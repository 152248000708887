import React, { useContext } from 'react';
import { GlobalDataContext } from "../../context/context";
import { FiTarget } from 'react-icons/fi'
import { MdRoofing } from 'react-icons/md'
import { FaHandshake } from 'react-icons/fa'


function Values() {
  const { rpdata } = useContext(GlobalDataContext);

  const valuesData = [
    {
      title: rpdata?.dbValues?.[1].title,
      description: rpdata?.dbValues?.[1].description,
      icon: <MdRoofing fontSize={90} className='bg-white p-4 rounded-full' />,
    },
    {
      title: rpdata?.dbValues?.[0].title,
      description: rpdata?.dbValues?.[0].description,
      icon: <FiTarget fontSize={90} className='bg-white p-4 rounded-full' />,
    },
    {
      title: rpdata?.dbValues?.[2].title,
      description: rpdata?.dbValues?.[2].description,
      icon: <FaHandshake fontSize={90} className='bg-white p-4 rounded-full' />,
    },
  ]


  return (
    <div className='w-full h-auto relative'>
      <div className='w-[80px] h-[80px] absolute top-0 left-1/2 transform hover:scale-105 duration-300 ease-in-out md:block hidden'>
        <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/bubble-1.png?alt=media&token=54a58d49-8432-4ae9-ae1c-22edb0761a12&_gl=1*pinu31*_ga*ODgwNTY3MjU1LjE2NjEwMTIyMDI.*_ga_CW55HF8NVT*MTY4NjE2NDcwNS41NS4xLjE2ODYxNjQ3MzcuMC4wLjA." alt="burble"
          className='w-full h-full object-contain'
        />
      </div>
      <div className='w-[150px] h-[150px] absolute top-36 left-6 transform hover:scale-105 duration-300 ease-in-out md:block hidden'>
        <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/bubble-1.png?alt=media&token=54a58d49-8432-4ae9-ae1c-22edb0761a12&_gl=1*pinu31*_ga*ODgwNTY3MjU1LjE2NjEwMTIyMDI.*_ga_CW55HF8NVT*MTY4NjE2NDcwNS41NS4xLjE2ODYxNjQ3MzcuMC4wLjA." alt="burble"
          className='w-full h-full object-contain'
        />
      </div>
      <div className='w-[180px] h-[180px] absolute bottom-36 right-6 transform hover:scale-105 duration-300 ease-in-out md:block hidden'>
        <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/bubble-1.png?alt=media&token=54a58d49-8432-4ae9-ae1c-22edb0761a12&_gl=1*pinu31*_ga*ODgwNTY3MjU1LjE2NjEwMTIyMDI.*_ga_CW55HF8NVT*MTY4NjE2NDcwNS41NS4xLjE2ODYxNjQ3MzcuMC4wLjA." alt="burble"
          className='w-full h-full object-contain'
        />
      </div>
      <div className="custom-shape-divider-bottom-1686160599">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
        </svg>
      </div>
      <div className="shape-top">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
        </svg>
      </div>
      <div className='w-full h-auto bg1 py-28'>
        <div className='text-center w-full '>
          <h1 className='text-white'>DOMI Makes The Difference</h1>
        </div>
        <div className="mx-auto md:w-full flex md:flex-row flex-col justify-center items-center py-10 bg1 space-y-0 md:space-x-28">

          {
            valuesData.map((items, index) => {
              return (
                <div key={index} className=" w-80 h-auto md:text-start text-center  p-5 cursor-pointer rounded transform hover:scale-105 duration-300 ease-in-out">
                  <div className="flex justify-center">
                    {items.icon}
                  </div>
                  <div className="p-4 ">
                    <h2 className="text-2xl text-center uppercase text-black">{items.title}</h2>
                    <p className='text-center pb-3 text-black'>
                      {items.description}
                    </p>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  );
}

export default Values;
